@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": #339dff,
  "primary-outline": #0040bf,
  "secondary": #5d1c9f,
  "secondary-outline": #3b2a7f,
  "success": #28a745,
  "danger": #eb455f,
  "warning": #ffe10a,
  "info": #43b5ff,
  "light": #f6f8fa,
  "dark": #343a40,
  "white": #ffffff,
  "transparent": transparent,
  "turquoise": #ccf1eb,
  "turquoise-text": #06bb9b,
  "orange": #fce5cd,
  "orange-text": #fb8b15,
  "dark-orange": #fed4bd,
  "dark-orange-text": #d8875d,
  "pink": #ffbfcc,
  "pink-text": #ff0155,
  "red": #f4cccc,
  "red-text": #9a0000,
  "dark-red": #e6b8af,
  "dark-red-text": #85210c,
  "grey": #cccccc,
  "grey-text": #999999,
  "light-grey": #d9d9d9,
  "light-grey-text": #999999,
  "green": #d9ead3,
  "green-text": #88b077,
  "light-blue": #d5f3ff,
  "light-blue-text": #00acee,
  "blue": #d0e2f4,
  "blue-text": #083763,
  "dark-blue": #a5c2f4,
  "dark-blue-text": #1d4587,
  "dark-green": #b6d7a7,
  "dark-green-text": #38761d,
  "brown": #edcf99,
  "brown-text": #a87723,
  "purple": #d9d2e9,
  "purple-text": #a01aa5,
  "dark-yellow": #ffe599,
  "dark-yellow-text": #bf9001,
  "light-yellow": #fff2cc,
  "light-yellow-text": #f0c33f,
  "black": #000,
  "black-text": #fff,
  "black-contrast": #000,
  "black-dark-contrast": #fff,
  "black-italics": #000,
  "black-italics-text": #fff,
  "black-italics-contrast": #000,
  "black-italics-dark-contrast": #fff,
  "dark-grey": #b7b7b7,
  "dark-grey-text": #343a40,
  "navlink": #888,
);

@font-face {
  font-family: "Circular Std Medium";
  src: url("/fonts/CircularStd-Medium.eot"); /* IE9 Compat Modes */
  src: url("/fonts/CircularStd-Medium.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/CircularStd-Medium.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/CircularStd-Medium.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/CircularStd-Medium.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/CircularStd-Medium.svg#svgFontName") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Circular Std Black";
  src: url("/fonts/CircularStd-Black.eot"); /* IE9 Compat Modes */
  src: url("/fonts/CircularStd-Black.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/CircularStd-Black.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/fonts/CircularStd-Black.woff") format("woff"),
    /* Pretty Modern Browsers */ url("/fonts/CircularStd-Black.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/fonts/CircularStd-Black.svg#svgFontName") format("svg"); /* Legacy iOS */
}

// TEXT
$font-family-sans-serif: "Circular Std Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px,
);

// SEE DOCS FOR MORE:
// https://bit.ly/2sgFMdb

// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

@supports (color-scheme: dark) {
}

html {
  --body-bg: #{$body-bg};
  --body-color: #{$body-color};
  --card-overlay: rgba(255, 255, 255, 0.5);
  --contrast-overlay: rgba(0, 0, 0, 0.5);

  .modal-content {
    background: var(--body-bg);

    &,
    div {
      border-color: var(--contrast-overlay);
    }
  }
}

html[darkmode] {
  // Simply switch background & text color
  --body-color: #{$body-bg};
  --body-bg: #{$body-color};
  --card-overlay: rgba(0, 0, 0, 0.5);
  --contrast-overlay: rgba(255, 255, 255, 0.5);

  --navlink: #bbb;

  .list-group-item {
    &:not(.active) {
      background-color: var(--body-bg);
      border-color: rgba(255, 255, 255, 0.25);
    }
  }

  a {
    color: var(--primary);
  }

  .btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .navbar-light .navbar-nav .nav-link {
    color: var(--navlink);
    &:hover {
      color: #ddd;
    }
  }

  .dropdown-menu {
    background-color: var(--body-bg);
    border-color: rgba(255, 255, 255, 0.35);
  }
}

body {
  background-color: var(--body-bg);
  color: var(--body-color);
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / 8);
    $rot: 90 - ($item-count - 1) * ($angle / 2);

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * -1deg) translate($circle-size / -2) rotate($rot * 1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

@mixin role-col($width, $badge-width, $padding) {
  flex-grow: 0;
  flex-basis: calc(#{$width} + #{$padding});

  .role-badge-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .role-badge-list {
      width: 100%;
      height: 100%;

      @for $i from 1 through 10 {
        &:has(li:nth-child(#{$i}):last-child) {
          @include on-circle($item-count: $i, $circle-size: $width, $item-size: $badge-width);
        }
      }

      .role-badge {
        display: block;
        max-width: 100%;
        transition: 0.15s;
        z-index: 1000;
      }
    }
  }
}

.role-col-lg {
  @include role-col($width: 9em, $badge-width: 2em, $padding: 30px);
}

.role-col-md {
  @include role-col($width: 7em, $badge-width: 1.75em, $padding: 30px);
}

.role-col-sm {
  @include role-col($width: 5em, $badge-width: 1.25em, $padding: 30px);
}
